/**
 * Copyright 2017, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */
const axios = require('axios');

function getFeature(layerId, mapPos, mapCrs, mapScale, dpi, callback) {
    console.log("Pick " + layerId + "at"+mapScale+" at (" + mapPos[0] + ", " + mapPos[1] + "): " + mapCrs);
    let feature = null;
    axios.post('https://gisapa.qtibia.ro/get_feature', {
        layerId: layerId,
        mapPos: mapPos,
        mapCrs: mapCrs,
        mapScale: mapScale,
        dpi: dpi

    }).then(function (response) {
        feature = response.data;
        setTimeout(() => callback(feature), 500);
    }).catch(function (error) {
            console.log(error);
            setTimeout(() => callback(feature), 500);
        });

}

function addFeature(layerId, feature, mapCrs, callback) {
    console.log("Add to layer " + layerId + ":");
    let success = false;
    let errorMsg = "Commit failed";
    axios.post('https://gisapa.qtibia.ro/add_feature', {
        layerId: layerId,
        feature: feature,
        mapCrs: mapCrs

    }).then(function (response) {
        let success = true;
        setTimeout(() => callback(success, errorMsg), 500);

    }) .catch(function (error) {
             setTimeout(() => callback(success, errorMsg), 500);
        });
}

function editFeature(layerId, feature, mapCrs, callback) {
    console.log("Commit to layer " + layerId + ":");
    let success = false;
    let errorMsg = "Commit failed";

    axios.post('https://gisapa.qtibia.ro/edit_feature', {
        layerId: layerId,
        feature: feature,
        mapCrs: mapCrs

    }).then(function (response) {
        let success = true;
        setTimeout(() => callback(success, errorMsg), 500);

    }) .catch(function (error) {
             setTimeout(() => callback(success, errorMsg), 500);
        });


}

function deleteFeature(layerId, featureId, callback) {
    console.log("Delete feature from layer " + layerId + ":" + featureId);
    let success = false;
    let errorMsg = "Commit failed";
    axios.post('https://gisapa.qtibia.ro/delete_feature', {
        layerId: layerId,
        featureId: featureId

    }).then(function (response) {
        let success = true;
        setTimeout(() => callback(success, errorMsg), 500);

    }) .catch(function (error) {

             setTimeout(() => callback(success, errorMsg), 500);
        });
}

module.exports = {
    getFeature,
    addFeature,
    editFeature,
    deleteFeature
}
